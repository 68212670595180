<template>
  <div>
    <b-modal
      id="edit-finance-account"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('EditFinanceAccount')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('BankName')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-select
                v-model="editForm.bankId"
                :placeholder="$t('BankName')"
                label="name"
                :options="getBankAccount"
                :reduce="(e) => e.id"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('Name')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input
                v-model="editForm.name"
                :placeholder="$t('Name')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('IBAN')"
              style="width: 50%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.iban"
                :placeholder="$t('IBAN')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('CreditCardType')"
              style="width: 50%;padding-bottom: 10px;"
            >
              <vue-select
                v-model="editForm.creditCardType"
                :placeholder="$t('CreditCardType')"
                :options="cardType"
                :reduce="(e) => e.text"
                label="text"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: ['accountDetailss'],
  data() {
    return {
      isPhoneNumberValid: null,
      editForm: {
        iban: null,
        creditCardType: null,
        name: null,
        accountId: null,
        bankId: null,
      },
      cardType: [
        { text: 'Visa', value: 0 },
        { text: 'MasterCard', value: 1 },
        { text: 'AmericanExpress', value: 2 },
        { text: 'Discover', value: 3 },
        { text: 'Amex', value: 4 },
        { text: 'BCGlobal', value: 5 },
        { text: 'CarteBlanch', value: 6 },
        { text: 'DinersClub', value: 7 },
        { text: 'InstaPaymentCard', value: 8 },
        { text: 'JCBCard', value: 9 },
        { text: 'KoreanLocal', value: 10 },
        { text: 'LaserCard', value: 11 },
        { text: 'Maestro', value: 12 },
        { text: 'Solo', value: 13 },
        { text: 'SwitchCard', value: 14 },
        { text: 'UnionPay', value: 15 },
        { text: 'NotFormatted', value: 16 },
        { text: 'Unknown', value: 17 },
      ],
    }
  },
  computed: {
    ...mapGetters(['getBankAccount']),
  },
  watch: {
    accountDetailss(value) {
      this.editForm.iban = value.iban
      this.editForm.creditCardType = value.creditCardType
      this.editForm.name = value.name
      this.editForm.accountId = value.accountId
      this.editForm.bankId = value.bankId
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.editForm[name];
      return $dirty ? !$error : null;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.editForm.telephoneNumber = n.nationalNumber
        this.editForm.countryCode = n.countryCode
      } else {
        this.editForm.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onSubmit() {
      // this.$v.editForm.$touch();
      // if (this.$v.editForm.$anyError) {
      //   return;
      // }
      this.$emit('editAccount', this.editForm)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.editForm.iban = this.accountDetailss.iban
      this.editForm.creditCardType = this.accountDetailss.creditCardType
      this.editForm.name = this.accountDetailss.name
      this.editForm.accountId = this.accountDetailss.accountId
      this.editForm.bankId = this.accountDetailss.bankId
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
