<template>
  <div>
    <b-modal
      id="add-bank-account"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('AddBankAccount')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('BankName')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input
                v-model="form.name"
                :placeholder="$t('BankName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('Street')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.street"
                :placeholder="$t('Street')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('HouseNumber')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.houseNumber"
                :placeholder="$t('HouseNumber')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="`${$t('PhoneNumber')}`"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-phone-number-input
                v-model="form.phoneNumber"
                :default-country-code="form.countryCode"
                :preferred-countries="['CH', 'DE', 'AL']"
                :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                @update="getFormattedNumber"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('Email')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.email"
                :placeholder="$t('Email')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('DoorNumber')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.doorNumber"
                :placeholder="$t('DoorNumber')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('City')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.city"
                :placeholder="$t('City')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('Country')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.country"
                :placeholder="$t('Country')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('ZIP/PostalCode')"
              style="width: 50%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.postalCode"
                :placeholder="$t('ZIP/PostalCode')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: [],
  data() {
    return {
      isPhoneNumberValid: null,
      form: {
        name: null,
        street: null,
        houseNumber: null,
        phoneNumber: null,
        countryCode: null,
        email: null,
        doorNumber: null,
        city: null,
        country: null,
        postalCode: null,
      },
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.form.phoneNumber = n.nationalNumber
        this.form.countryCode = n.countryCode
      } else {
        this.form.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      this.$emit('addBank', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.name = null
      this.form.street = null
      this.form.houseNumber = null
      this.form.phoneNumber = null
      this.form.countryCode = null
      this.form.email = null
      this.form.doorNumber = null
      this.form.city = null
      this.form.country = null
      this.form.expiryDate = null
      this.form.postalCode = null
      this.form.iban = null
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
