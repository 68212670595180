<template>
  <div>
    <b-modal
      id="add-finance-account"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('AddFinanceAccount')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('BankId')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-select
                v-model="form.bankId"
                :options="getBankAccount"
                label="name"
                :reduce="(e) => e.id"
                :placeholder="$t('BankId')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('Name')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.name"
                :placeholder="$t('Name')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('IBAN')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="form.iban"
                :placeholder="$t('IBAN')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('CreditCardType')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-select
                v-model="form.creditCardType"
                :placeholder="$t('CreditCardType')"
                :options="cardType"
                :reduce="(e) => e.text"
                label="text"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: [],
  data() {
    return {
      isPhoneNumberValid: null,
      form: {
        bankId: null,
        name: null,
        iban: null,
        creditCardType: null,
      },
      cardType: [
        { text: 'Visa', value: 0 },
        { text: 'MasterCard', value: 1 },
        { text: 'AmericanExpress', value: 2 },
        { text: 'Discover', value: 3 },
        { text: 'Amex', value: 4 },
        { text: 'BCGlobal', value: 5 },
        { text: 'CarteBlanch', value: 6 },
        { text: 'DinersClub', value: 7 },
        { text: 'InstaPaymentCard', value: 8 },
        { text: 'JCBCard', value: 9 },
        { text: 'KoreanLocal', value: 10 },
        { text: 'LaserCard', value: 11 },
        { text: 'Maestro', value: 12 },
        { text: 'Solo', value: 13 },
        { text: 'SwitchCard', value: 14 },
        { text: 'UnionPay', value: 15 },
        { text: 'NotFormatted', value: 16 },
        { text: 'Unknown', value: 17 },
      ],
    }
  },
  computed: {
    ...mapGetters(['getBankAccount']),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.form.telephoneNumber = n.nationalNumber
        this.form.countryCode = n.countryCode
      } else {
        this.form.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      this.$emit('addFinance', this.form)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.form.bankId = null
      this.form.name = null
      this.form.iban = null
      this.form.creditCardType = null
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
