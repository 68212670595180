<template>
  <div>
    <b-modal
      id="edit-budget-sub-type"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('EditBudgetSubType')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <div style="display: flex; width: 100%; gap: 2%; align-items: center">
          <b-form-group
            :label="$t('Name')"
            style="width: 53%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="editForm.name"
              :placeholder="$t('Name')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Description')"
            style="width: 53%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="editForm.description"
              :placeholder="$t('Description')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
        </div>
        <div style="display: flex; width: 100%; gap: 2%; align-items: center">
          <b-form-group
            :label="$t('BudgetCode')"
            style="width: 52%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="editForm.budgeSubCode"
              :placeholder="$t('BudgetCode')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>

          <b-form-group
            id="input-group-1"
            :label="`${$t('Hash_Colorr')}:`"
            label-for="input-1"
            style="width: 35%"
          >
            <b-form-input
              id="input-1"
              v-model="editForm.hashColor"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_7_characters') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-for="input-2"
            :label="`${$t('Color_Pick')}:`"
            style="width: 15%;"
          >
            <b-form-input
              id="input-2"
              v-model="editForm.hashColor"
              type="color"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapGetters } from 'vuex';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: ['budgetSubTypeDetailss'],
  data() {
    return {
      id: null,
      budgetCode: null,
      editForm: {
        name: null,
        description: null,
        budgeSubCode: null,
        hashColor: null,
        id: null,
      },
    }
  },
  computed: {
    ...mapGetters(['getBudgetSubType']),
  },
  watch: {
    budgetSubTypeDetailss(value) {
      this.editForm.name = value.name
      this.editForm.description = value.description
      this.editForm.budgeSubCode = value.budgetSubCode
      this.editForm.hashColor = value.hashColoror
      this.editForm.id = value.id
    },
  },
  methods: {
    onSubmit() {
      // this.$v.editForm.$touch();
      // if (this.$v.editForm.$anyError) {
      //   return;
      // }
      this.$emit('editBudgetSubTypee', this.editForm)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.editForm.name = this.budgetSubTypeDetailss.name
      this.editForm.description = this.budgetSubTypeDetailss.description
      this.editForm.budgeSubCode = this.budgetSubTypeDetailss.budgetSubCode
      this.editForm.hashColor = this.budgetSubTypeDetailss.hashColoror
      this.editForm.id = this.budgetSubTypeDetailss.id
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
