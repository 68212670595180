<template>
  <div>
    <div>
      <button
        v-b-modal.add-budget-type
        class="button"
        style="margin-top: 3%;"
      >
        {{ $t('AddBudgetType') }}
      </button>
    </div>
    <div style="width: 100%;padding-top: 16px; margin-top: 0px;">
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Name') }}
            </th>
            <th>
              {{ $t('Description') }}
            </th>
            <th>
              {{ $t('BudgetCode') }}
            </th>
            <th>
              {{ $t('TransactionType') }}
            </th>
            <th>
              {{ $t('HashColor') }}
            </th>
            <th>
              {{ $t('Edit') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(budget, index) in getBudgetType"
            :key="index"
            class="hover-row"
            :style="classNameCh === budget.id ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="matProps(budget)"
          >
            <td>
              {{ budget.name }}
            </td>
            <td>{{ budget.description }}</td>
            <td>{{ budget.budgetCode }}</td>
            <td>{{ budget.transactionType }}</td>
            <td><span
              class="colored-circle"
              :style="`background-color: ${budget.hashColor}; margin-left: 20px; box-shadow: none;`"
            />
            </td>
            <td><p
              v-b-modal.edit-budget-type
              class="p-0 m-0 ml-50"
              @click="budgetTypeDetailss(budget)"
            >
              <b-icon-pencil />
            </p></td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddBudgetType @addBudgetTypee="addBudgetTypee" />
    <EditBudgetType
      :budget-type-detailss="budgetTypeDetails"
      @editBudgetTypee="editBudgetTypee"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddBudgetType from '@/components/employeePayments/modals/AddBudgetType.vue'
import EditBudgetType from '@/components/employeePayments/modals/EditBudgetType.vue'

export default {
  components: {
    AddBudgetType,
    EditBudgetType,
  },
  data() {
    return {
      budgetId: null,
      budgetTypeDetails: null,
      selectedBudgetTypeId: null,
    };
  },
  computed: {
    ...mapGetters(['getBudgetType', 'getBudgetSubTypeByBudgetId']),
  },
  mounted() {
    this.budgetTypes()
  },
  methods: {
    ...mapActions([
      'addBudgetType',
      'budgetTypes',
      'budgetSubTypes',
      'editBudgetType',
      'budgetSubTypeByBudgetId']),
    async addBudgetTypee(obj) {
      console.log(obj)
      await this.addBudgetType({
        object: obj,
        successCallback: () => {
          this.budgetTypes()
        },
      })
    },
    matProps(value) {
      this.classNameCh = value.id
      this.$emit('budgetTypeId', value);
      this.budgetSubTypes({ budgetTypeId: value.id })
    },
    async editBudgetTypee(obj) {
      await this.editBudgetType({
        object: obj,
        successCallback: () => {
          this.budgetTypes()
        },
      })
    },
    budgetTypeDetailss(value) {
      this.budgetTypeDetails = value
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 10px;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.selected-row {
    background-color: rgba(255, 39, 79, 0.1);
}
</style>
