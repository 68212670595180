<template>
  <div>
    <b-modal
      id="edit-bank-account"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('EditBankAccount')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; flex-direction: column; width: 100%; justify-content: space-between;">
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('BankName')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input
                v-model="editForm.name"
                :placeholder="$t('BankName')"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('Street')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.street"
                :placeholder="$t('Street')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('HouseNumber')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.houseNumber"
                :placeholder="$t('HouseNumber')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="`${$t('PhoneNumber')}`"
              style="width: 100%;padding-bottom: 10px;"
            >
              <vue-phone-number-input
                v-model="editForm.phoneNumber"
                :default-country-code="editForm.countryCode"
                :preferred-countries="['CH', 'DE', 'AL']"
                :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                @update="getFormattedNumber"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('Email')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.email"
                :placeholder="$t('Email')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('DoorNumber')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.doorNumber"
                :placeholder="$t('DoorNumber')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('City')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.city"
                :placeholder="$t('City')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
            <b-form-group
              :label="$t('Country')"
              style="width: 100%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.country"
                :placeholder="$t('Country')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
          <div style="display: flex; justify-content: space-between;gap: 10px;">
            <b-form-group
              :label="$t('ZIP/PostalCode')"
              style="width: 50%;padding-bottom: 10px;"
            >
              <b-form-input

                v-model="editForm.postalCode"
                :placeholder="$t('ZIP/PostalCode')"
                aria-describedby="input-2-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: ['bankDetailss'],
  data() {
    return {
      isPhoneNumberValid: null,
      editForm: {
        name: null,
        phoneNumber: null,
        email: null,
        countryCode: null,
        doorNumber: null,
        postalCode: null,
        street: null,
        city: null,
        country: null,
        houseNumber: null,
        bankId: null,
      },
    }
  },
  watch: {
    bankDetailss(value) {
      this.editForm.name = value.name
      this.editForm.phoneNumber = value.phoneNumber
      this.editForm.email = value.email
      this.editForm.countryCode = value.countryCode
      this.editForm.doorNumber = value.doorNumber
      this.editForm.postalCode = value.postalCode
      this.editForm.street = value.street
      this.editForm.city = value.city
      this.editForm.country = value.country
      this.editForm.houseNumber = value.houseNumber
      this.editForm.bankId = value.bankId
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.editForm[name];
      return $dirty ? !$error : null;
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.editForm.telephoneNumber = n.nationalNumber
        this.editForm.countryCode = n.countryCode
      } else {
        this.editForm.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onSubmit() {
      this.$emit('editBank', this.editForm)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.editForm.name = this.bankDetailss.name
      this.editForm.phoneNumber = this.bankDetailss.phoneNumber
      this.editForm.email = this.bankDetailss.email
      this.editForm.countryCode = this.bankDetailss.countryCode
      this.editForm.doorNumber = this.bankDetailss.doorNumber
      this.editForm.postalCode = this.bankDetailss.postalCode
      this.editForm.street = this.bankDetailss.street
      this.editForm.city = this.bankDetailss.city
      this.editForm.country = this.bankDetailss.country
      this.editForm.houseNumber = this.bankDetailss.houseNumber
      this.editForm.bankId = this.bankDetailss.bankId
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
