<template>
  <div>
    <b-modal
      id="add-budget-type"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      size="lg"
      :title="$t('AddBudgetType')"
      hide-footer
    >
      <b-form>
        <div style="display: flex; width: 100%; gap: 2%; align-items: center">
          <b-form-group
            :label="$t('Name')"
            style="width: 53%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="name"
              :placeholder="$t('Name')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Description')"
            style="width: 53%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="description"
              :placeholder="$t('Description')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
        </div>
        <div style="display: flex; width: 100%; gap: 2%; align-items: center">
          <b-form-group
            :label="$t('TransactionType')"
            style="width: 100%;padding-bottom: 10px;"
          >
            <vue-select
              v-model="transactionType"
              :placeholder="$t('TransactionType')"
              :options="transactionTypes"
              :reduce="(e) => e.value"
              label="name"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <b-form-group
            :label="$t('BudgetCode')"
            style="width: 100%;padding-bottom: 10px;"
          >
            <b-form-input
              v-model="budgetCode"
              :placeholder="$t('BudgetCode')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
        </div>
        <div style="display: flex; width: 100%">
          <b-form-group
            id="input-group-1"
            :label="`${$t('Hash_Colorr')}:`"
            label-for="input-1"
            style="width: 35%"
          >
            <b-form-input
              id="input-1"
              v-model="hashColor"
              aria-describedby="input-1-live-feedback"
            />
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t('This_is_a_required_field_and_must_be_at_least_7_characters') }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label-for="input-2"
            :label="`${$t('Color_Pick')}:`"
            style="width: 15%;"
          >
            <b-form-input
              id="input-2"
              v-model="hashColor"
              type="color"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </div>
      </b-form>

      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('VacationSubmit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import moment from 'moment-timezone';

export default {
  components: {
    // DatePicker,
  },
  props: [],
  data() {
    return {
      name: null,
      description: null,
      transactionType: null,
      budgetCode: null,
      hashColor: null,
      transactionTypes: [
        { name: 'Debit', value: 'Debit' },
        { name: 'Credit', value: 'Credit' },
      ],
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      const objekti = {
        name: this.name,
        description: this.description,
        transactionType: this.transactionType,
        budgetCode: this.budgetCode,
        hashColor: this.hashColor,
      }
      this.$emit('addBudgetTypee', objekti)
      this.$refs.modal.hide()
      this.resetForm()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.hashColor = null
      this.name = null
      this.description = null
      this.transactionType = null
      this.budgetCode = null
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
