<template>
  <div>
    <Accounts />
  </div>
</template>

<script>
import Accounts from '../components/employeePayments/Accounts.vue'

export default {
  components: {
    Accounts,
  },
}
</script>

<style scoped lang="scss">


</style>
