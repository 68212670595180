<template>
  <div>
    <b-modal
      id="account-activate"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('ActivateAccount')}`"
      hide-footer
    >

      <p>{{ $t("YouAreAA") }}</p>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Activate") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal></div>
</template>

<script>

export default {
  props: ['accountUser'],
  data() {
    return {}
  },
  methods: {
    onSubmit() {
      const objekti = {
        accountId: this.accountUser,
        newActivationStatus: true,
      }
      this.$emit('active', objekti)
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$refs.modal.hide()
    },
  },
}

</script>

<style lang="scss" scoped>
  form {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .deactivate-modal__select {
    display: flex;
    flex-direction: column;
    select {
      margin-bottom: 10px;
      padding: 10px;
    }
    button {
      align-self: end;
    }
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .my-class {
  background: black;
  font-weight: bold;
}

  // .my-second-class > .modal-dialog > .modal-content > .modal-header {
  // background: black;
  // color: white;
  // }
</style>
