<template>
  <div>
    <div>
      <button
        v-b-modal.add-budget-sub-type
        class="button"
        style="margin-top: 5.5%; width: 240px"
      >
        {{ $t('AddBudgetSubType') }}
      </button>
    </div>
    <div
      style="width: 100%;padding-top: 16px; margin-top: 0px;"
    >
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('Name') }}
            </th>
            <th>
              {{ $t('Description') }}
            </th>
            <th>
              {{ $t('BudgetSubCode') }}
            </th>
            <th>
              {{ $t('HashColor') }}
            </th>
            <th>
              {{ $t('Edit') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(budget, index) in getBudgetSubType"
            :key="index"
          >
            <td>{{ budget.name }}</td>
            <td>{{ budget.description }}</td>
            <td>{{ budget.budgetSubCode }}</td>
            <td><span
              class="colored-circle"
              :style="`background-color: ${budget.hashColoror}; margin-left: 20px; box-shadow: none;`"
            />
            </td>
            <td><p
              v-b-modal.edit-budget-sub-type
              class="p-0 m-0 ml-50"
              @click="budgetSubTypeDetailss(budget)"
            >
              <b-icon-pencil />
            </p></td>
          </tr>
        </tbody>
      </table>
    </div>
    <AddBudgetSubType
      :budget-type-id="budgetTypeId"
      @addBudgetSubTypee="addBudgetSubTypee"
    />
    <EditBudgetSubType
      :budget-sub-type-detailss="budgetSubTypeDetails"
      @editBudgetSubTypee="editBudgetSubTypee"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddBudgetSubType from '@/components/employeePayments/modals/AddBudgetSubType.vue'
import EditBudgetSubType from '@/components/employeePayments/modals/EditBudgetSubType.vue'

export default {
  components: {
    AddBudgetSubType,
    EditBudgetSubType,
  },
  props: ['budgetTypeId'],
  data() {
    return {
      budgetSubTypeDetails: null,
    };
  },
  computed: {
    ...mapGetters(['getBudgetSubType']),
  },
  watch: {
    budgetTypeId(newId) {
      this.budgetSubTypes({ budgetTypeId: newId });
    },
  },
  methods: {
    ...mapActions(['addBudgetSubType', 'budgetSubTypes', 'editBudgetSubType']),
    async addBudgetSubTypee(obj) {
      await this.addBudgetSubType({
        object: obj,
        successCallback: () => {
          this.budgetSubTypes({
            budgetTypeId: this.budgetTypeId,
          })
        },
      })
    },
    async editBudgetSubTypee(obj) {
      await this.editBudgetSubType({
        object: obj,
        successCallback: () => {
          this.budgetSubTypes({
            budgetTypeId: this.budgetTypeId,
          })
        },
      })
    },
    budgetSubTypeDetailss(value) {
      this.budgetSubTypeDetails = value
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 10px;
}
</style>
